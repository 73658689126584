// Browser compatibility
@mixin background-props {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin mobile-only {
	@media screen and (max-width: 64rem) {
		@content;
	}
}

@mixin tablet {
	@media screen and (min-width: 40rem) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: 64rem) {
		@content;
	}
}

// Used to compile CSS to display retina images.
// Example: @include background-image-retina(logo, png, 210px, 70px);
@mixin background-image-retina($file, $type, $width, $height) {
	background-image: url("assets/images/") $file + "." + $type;

	@media only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (-moz-min-device-pixel-ratio: 2),
		only screen and (-o-min-device-pixel-ratio: 2 / 1),
		only screen and (min-device-pixel-ratio: 2),
		only screen and (min-resolution: 192dpi),
		only screen and (min-resolution: 2dppx) {
		background-image: url("assets/images/") $file + "@2x." + $type;
		background-size: $width $height;
	}
}
